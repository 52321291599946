.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 320px;
    padding: 60px 25px 40px 50px;
    height: 100vh;
    background-color: #FFFFFF;
    position: relative;
    box-shadow: -8px 0px 10px rgba(132, 147, 173, 0.1);
    border-bottom: 8px solid #4140DF;

    @include breakPoint(medium) {
        display: none;
    }

    .frame {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
    }

    .sidebar-logo {
        width: 84px;
    }

    .todo-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 100px 0;

        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        color: #142037;

        img {
            height: 18px;
        }
    }
}

.sidebar-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 110px;
    width: 156px;
    z-index: 2;

    .sidebar-links {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #EEF2FA;

        a {
            color: #C3D0E4;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.03em;
            text-transform: uppercase;

            &:hover {
                @include themify($themes) {
                    color: themed('primaryFontColor');  
                }
            }
        }
    }
}
  
.rs-sidenav {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .rs-sidenav-body {
        margin-top: 60px;
    }
}

.steps-wrapper {
    text-align: left;
    width: 250px;
    display: inline-table;
    vertical-align: top;
  }
  
.rs-steps-item-tail {
    display: none;
}

.rs-steps-item-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
}

.rs-steps-item-icon-wrapper {
    &:not(.rs-steps-item-custom-icon) {
        border: 2px solid #8493AD;
        color: #8493AD;
        background: #FFFFFF;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        line-height: 20px;
        font-weight: 500;
        font-size: 15px;
    }

    .rs-steps-item-icon {
        font-weight: 700;
    }
}

.rs-steps-item-status-process {
    .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
        color: #4140DF;
        border-color: #4140DF;
        background: #FFFFFF;
    }
}

.rs-steps-item-status-finish {
    .rs-steps-item-title {
        color: #C3D0E4;
    }

    .rs-steps-item-icon-wrapper {
        border: none;

        &:not(.rs-steps-item-custom-icon) {
            .rs-steps-item-icon {
                &:before {
                    color: #FFFFFF;
                    background: #69F0AE;
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    line-height: 26px;
                    font-weight: 500;
                    font-size: 15px;
                }
            }
        }
    }
}

.rs-steps-item-status-process > .rs-steps-item-content > .rs-steps-item-title {
    color: #4140DF;
    font-weight: 700;
}