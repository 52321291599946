.companies-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 840px;
    align-items: flex-start;
    height: 100%;
    word-break: break-word;
}
  
.companies-cta-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 50px 0;

    @include breakPoint(small) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        h2 {
            margin-bottom: 30px;
        }
    }
}

.company-details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 900px;
    padding: 5% 10%; 

    @include breakPoint(medium) {
        padding-bottom: 100px;
    }

    @include breakPoint(small) {
        width: 100%;
    }

    .company-details-back {
        position: relative;
        top: 30px;
        display: flex;
        align-self: flex-start;

        @include breakPoint(small) {
            top: -10px;
        }
    }

    .company-details {
        width: 100%;
        word-break: break-word;
        border-top: 1px solid #E2E5EC;
        padding-top: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        @include breakPoint(small) {
            flex-direction: column;
            flex-wrap: unset;
        }

        .company-details-item {
            margin-bottom: 30px;
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            & .status {
                width: auto;
                margin-top: 8px;
            }
        }
    }

    .company-number-wrapper {
        font-weight: 600;
        margin: 15px 0 25px;
        .company-number {
            font-weight: bold;
        }
    }

    .rs-nav {
        width: 450px;
        margin-bottom: 30px !important;

        @include breakPoint(small) {
            max-width: 400px;
            width: auto;
        }

        & > ul {
            display: flex;
            justify-content: space-between;

            @include breakPoint(small) {
                flex-direction: column;
            }
        }

        .rs-nav-waterline {
            display: none;
        }

        .rs-nav-item {
            background: #FFFFFF;
            border-radius: 100px;
            width: 120px;
            height: 32px;

            @include breakPoint(small) {
                margin-bottom: 20px;
            }

            &.rs-nav-item-active {
                background: #306ADC;
                 
                .rs-nav-item-content {
                    color: #ffffff;

                    &:hover {
                        color: #ffffff;
                    }
                }
            }

            .rs-nav-item-content {
                &:before {
                    content: none;
                }
            }
    
            .rs-nav-item-content {
                display: flex;
                justify-content: center;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 13px;
                line-height: 20px;
                padding-top: 6px;
                color: #7A869B;

                &:hover {
                    color: $primary-font-color-companyHouse;
                }
            }
        }
    }

}

.status {
    padding: 2px 12px 4px;
    border-radius: 100px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;

    &.active {
      color: #35C0A1;
      background: #DAF9F2;
    }
    &.pending {
      color: #F17105;
      background: #FFEDDE;
    }
}
