.landing-page-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    [data-aos] {
        z-index: 2;
    }

    .random-element-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    h2 {
        border-bottom: 24px solid #B5F3D8;
        line-height: 6px;
        margin-top: 50px;
        font-weight: 800;
        padding: 0 15px;
    }

    h4 {
        font-weight: bold;
        letter-spacing: 0.58em;
        text-transform: uppercase;
        color: #9DA5B2;
    }

    @include breakPoint(small) {
        h1 {
            font-size: 34px;
            line-height: 48px;
        }
        h2 {
            font-size: 32px;
            line-height: 6px;
            margin-top: 12px;
            border-bottom: 20px solid #B5F3D8;
        }
        h4 {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 30px;
        }
        h5 {
            font-size: 21px;
            line-height: 24px;
        }
        p {
            font-size: 15px;
            line-height: 30px;
        }
    }

    .heading-section {
        width: 100%;
        height: 100%;
        display: flex;

        .store-buttons {
            > a {
                margin-right: 20px;
            }

            @include breakPoint(small) {
                margin-right: 0;
                height: 124px;
                background-color: #8793AB;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;

                > a {
                    margin-right: 0;
                }
            }
        }

        .desktop {
            @include breakPoint(large) {
                display: flex;
            }

            @include breakPoint(small) {
                display: none;
            }

            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            position: relative;
            padding: 0 30px;

            .dots {
                position: absolute;
                top: -140px;
                right: -20px;
                z-index: -1;
            }

            .ellipse {
                position: absolute;
                bottom: -140px;
                right: -120px;
                z-index: 20;
                max-width: 25%;
            }

            .image-wrapper {
                display: inline-block;
                position: relative;
                width: 100%;
                max-width: 50%;
            }

            .portrait {
                z-index: 8;
                max-width: 100%;
                position: absolute;
                bottom: 0;
                @include breakPoint(medium) {
                    max-width: 400px;
                }
            }

            .content-wrapper {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                margin: 7% 0 13%;
                padding-right: 4%;

                @include breakPoint(medium) {
                    min-width: 322px;
                    padding-right: 0;
                }

                .content {
                    max-width: 370px;
                    z-index: 10;
    
                    p {
                        margin: 40px 0;
                    }
                }
            }
        }

        .mobile {
            @include breakPoint(large) {
                display: none;
            }

            @include breakPoint(small) {
                display: flex;
            }

            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            position: relative;
            

            .content {
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                z-index: 10;
                max-width: 380px;

                p {
                    margin: 20px 0;
                }
            }

            .portrait {
                z-index: 8;
                width: 100%;
                max-width: 440px;
                margin: 0 auto;
            }
        }
    }

    .control-identity-section {
        background: #F2F5FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 110px 30px 30px;

        @include breakPoint(small) {
            padding-top: 45px;
        }

        .content-wrapper {
            max-width: 960px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .illustration {
                width: 100%;
            }

            .text-content-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                z-index: 2;


                p {
                    margin-top: 40px;

                    @include breakPoint(small) {
                        margin-top: 30px;
                    }
                }

                .heading-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                }

                > * {
                    width: 50%;
                }

                @include breakPoint(small) {
                    flex-direction: column;

                    > * {
                        width: 100%;
                    }
                }
            }
        }
    }

    .selv-section {
        min-height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #151F35;

        p {
            margin-top: 50px;
            @include themify($themes) {
                font-family: themed('fontFamilyHeadings');
            }
            font-weight: 500;
            font-size: 25px;
            line-height: 39px;
            text-align: center;
            color: #FFFFFF;
            padding: 0 30px;

            @include breakPoint(small) {
                font-size: 16px;
                line-height: 28px;
            }
        }
    }

    .how-it-works-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 130px 30px 60px;

        @include breakPoint(small) {
            padding: 60px 0 30px;
        }

        .circle {
            position: absolute;
            top: -340px;
            left: -30px;
            z-index: -1;

            @include breakPoint(small) {
                display: none;
            }
        }

        .content-wrapper {
            max-width: 960px;
            width: 100%;
            display: flex;
            flex-direction: column;

            .desktop {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 100%;
                position: relative;
    
                @include breakPoint(small) {
                    display: none;
                }

                .column {
                    z-index: 2;
                    width: 50%;
                    max-width: 310px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    position: relative;
                    top: -80px;

                    &:first-of-type {
                        padding-top: 180px;
                        top: 0;
                    }

                    .info {
                        background-color: #fff;

                        &:last-of-type {
                            margin-top: 150px;
                        }

                        .content-header-wrapper {
                            margin-bottom: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        }
        
                        h5 {
                            margin-left: 20px;
                            line-height: 35px;
                            border-bottom: 5px solid #B5F3D8;
                        }
        
                        p {
                            margin-left: 55px;
                        }
                    }
                }

                .howItWorks1 {
                    width: 530px;
                    position: relative;
                    left: -40px;
                }

                .howItWorks2 {
                    width: 560px;
                    position: relative;
                    left: -180px;
                    top: 30px;
                }
            }
    
            .mobile {
                display: none;
                @include breakPoint(small) {
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    .logo {
                        display: none;
                    }

                    .content-header-wrapper {
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .info {
                        margin: 40px 30px 0;
                    }

                    .howItWorks1 {
                        width: 100%;
                    }

                    .howItWorks2 {
                        position: relative;
                        left: -30px;
                        top: 30px;
                        width: 100%;
                    }

                    h5 {
                        line-height: 35px;
                        border-bottom: 5px solid #B5F3D8;
                    }
                }
            }
        }
    }

    .benefits-section {
        background: #F2F5FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 130px 30px 30px;

        @include breakPoint(small) {
            padding: 60px 30px 0;
        }

        .benefits-wrapper {
            max-width: 990px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            margin: 110px 0;

            .benefit {
                z-index: 2;
                width: 300px;
                min-height: 255px;
                position: relative;
                padding: 70px 25px 30px;
                margin: 0 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                background: #FFFFFF;
                border-radius: 10px;
                text-align: center;

                img {
                    position: absolute;
                    top: -65px;
                }
            }

            @include breakPoint(small) {
                flex-direction: column;
                align-items: center;
                margin: 50px 0;

                .benefit {
                    margin: 40px 0;
                }
            }
        }
    }

    .app-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 130px 30px;

        @include breakPoint(small) {
            padding: 60px 30px 80px;
        }

        .circle {
            position: absolute;
            top: -340px;
            right: 0;
            z-index: -1;
        }

        .content-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            max-width: 1200px;
            width: 100%;

            .content-list {
                z-index: 2;
                margin: 70px 0;
            }

            .content-item {
                margin: 50px 0;
                max-width: 545px;

                .content-header-wrapper {
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                h5 {
                    margin-left: 20px;
                }

                p {
                    margin-left: 55px;
                }
            }

            .desktop {
                display: flex;
                max-width: 50%;
    
                @include breakPoint(small) {
                    display: none;
                }
            }
    
            .mobile {
                z-index: 2;
                display: none;
                @include breakPoint(small) {
                    display: flex;
                    flex-direction: column;
                    margin-top: 40px;
                }
            }

            @include breakPoint(small) {
                flex-direction: column;

                .logo {
                    display: none;
                }

                .content-list {
                    margin: 0;
                }

                .content-item {
                    margin: 40px 0;

                    h5 {
                        margin-left: 0;
                        margin-bottom: 5px;
                    }
    
                    p {
                        margin-left: 0;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .faq-section {
        background: #F2F5FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 130px 30px 60px;
        position: relative;

        .ellipse {
            position: absolute;
            top: -170px;
            right: -170px;

            @include breakPoint(small) {
                display: none;
            }
        }

        .circle {
            position: absolute;
            bottom: 0;
            left: 0;

            @include breakPoint(small) {
                display: none;
            }
        }

        .circle-mobile {
            display: none;

            @include breakPoint(small) {
                display: block;
                position: absolute;
                bottom: -77px;
                left: 0;
            }
        }

        .faq-wrapper {
            max-width: 810px;
            width: 100%;
        }

        .ant-collapse {
            z-index: 2;
            max-width: 810px;
            width: 100%;
            margin-top: 60px;
            background-color: transparent;

            .ant-collapse-header {
                padding: 0;

                .section-header {
                    h5 {
                        color: #ffffff;
                    }

                    height: 65px;
                    background: #151F35;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    padding: 10px 35px;
                }
            }

            .ant-collapse-item {
                margin: 25px 0;
                border: none;

                &.ant-collapse-item-active {
                    background-color: #ffffff;
                    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;

                    .ant-collapse-header {
                        .section-header {
                            h5 {
                                @include themify($themes) {
                                    color: themed('primaryFontColor');  
                                }
                            }

                            background: transparent;
                            -webkit-transition: background-color 250ms linear;
                            -ms-transition: background-color 250ms linear;
                            transition: background-color 250ms linear;
                        }
                    }
                }

                .faq-content {
                    padding: 0 20px 15px;
                }
            }
        }

        @include breakPoint(small) {
            padding: 60px 30px 30px;

            .ant-collapse {
                z-index: 2;

                .ant-collapse-item {
                    .section-header {
                        height: auto;

                        h5 {
                            font-size: 15px;
                            padding: 10px 10px 10px 0;
                        }
                    }
                }
            }
        }
    }
}
