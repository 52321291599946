.rs-table {
  @include themify($themes) {
    background: themed('pageBackground');
  }

  .rs-table-row-header, .rs-table-row, .rs-table-cell{
    @include themify($themes) {
      background: themed('pageBackground'); 
    }
  }
}

.rs-table-row {
    cursor: pointer;
    border-bottom: 1px solid #E2E5EC;
    
    .rs-table-cell, .rs-table-cell-first {
      .rs-table-cell-content {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        padding-left: 0 !important;
  
        .cell-content {
          .company-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
          }
          .company-type {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #6F6F6F;
          }
          &.status-wrapper {
            display: flex;
            flex-direction: row;
            border-radius: 100px;
          }
        }
      }
    }
  }
  
  .rs-table-cell-header {
    .rs-table-cell {
      .rs-table-cell-content {
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8593AC;
        padding-left: 0;
      }
    }
  }
  