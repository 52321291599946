.mobile-menu-icon {
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    display: block;
    padding: 16px 0 14px;

    span {
        @include themify($themes) {
            background: themed('primaryColor');
        }
        display: block;
        width: 24px;
        height: 3px;
        border-radius: 3px;
        transform-origin: center;
        transition: 300ms all cubic-bezier(.4,0,.2,1);
        
        &:nth-child(2) {
            margin: 4px 0;
        }
    }

    &.toggled {
        span {
            &:first-child {
                transform: translateY(6px) rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:last-child {
                transform: translateY(-8px) rotate(-45deg);
            }
        }
    }
}

.mobile-menu-wrapper {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    padding: 30px 0;

    &.open {
        transition: 0.5s;
        opacity: 1;
        z-index: 30;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
    }

    h1 {
        padding: 0 50px 30px;
    }

    .mobile-menu-links {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 100%;

        .menu-link {
            @include themify($themes) {
                font-family: themed('fontFamilyHeadings');
            }
            width: 100%;
            font-weight: 800;
            font-size: 17px;
            line-height: 57px;
            color: #142037;
            padding: 10px 50px;
            border-bottom: 1px solid rgba(132, 147, 173, 0.2);
        }

        a {
            &:last-of-type {
                width: 100%;
                padding: 50px;
            }
        }

        .cta {
            width: 100%;
        }
    }
}